<template>
  <div class="card">
    <div class="card-body pt-0 mt-5 steps">
        <el-form :model="formData" :rules="rules" ref="formRef" class="w-100 demo-ruleForm">
          <div class="col-md-12 mt-5 mb-5 d-flex justify-content-between">
            <div>
              <button type="button" size="large" v-bind:class="btnHide" :disabled="disabled" v-if="active > 0" class=" btn btn-primary me-2" :loading="loading" @click="previous">{{generalConstants.PREVIOUS}}</button>
            </div>
            <div>
              <button type="button" size="large" v-bind:class="btnHide" class="btn btn-primary me-2" v-if="active == 4" @click="submit">Confirm Order<span v-if="loading" class="spinner-border spinner-border-sm align-middle ms-2"></span></button>
              <button type="button" size="large" v-bind:class="btnHide" class="btn btn-primary me-2" v-if="active < 4" @click="next()">{{generalConstants.NEXT}}<span v-if="loading" class="spinner-border spinner-border-sm align-middle ms-2"></span></button>
              <button type="button" size="large" class=" btn btn-secondary me-2" v-bind:class="btnHide" @click.prevent="$router.push({ name: 'orders'})">{{generalConstants.CANCEL}}</button>
            </div>
        </div>
          <el-steps :active="active" finish-status="success" >
            <el-step title="Customer Details" />
            <el-step title="Product Details" />
            <el-step title="Shipping & Billing Address" />
            <el-step title="Order Details" />
            <el-step title="Payment details" class="text-end" />
          </el-steps>
          <div v-if="active===0">
            <div class="row mt-5">
              <div class="col-md-4">
                <label class="fs-6 fw-bold">Select Customer</label>
                <el-form-item prop="search_customer">
                <el-select filterable clearable class="w-100" @change="handleSelect($event)" size="large" placeholder="Select Customer" v-model="searchParams.search_customer">
                  <el-option :label="customerData.value" :value="customerData.value" :key="customerData" v-for="customerData in customers"></el-option>
                </el-select>
                </el-form-item>
              </div>
              <div class="col-md-4">
                <label class="required fw-bold">{{ generalConstants.SELECTTRADINGPARTNER }}</label>
                <el-form-item prop="step1.trading_partner_id" :rules="{ required: true, message: 'Trading Partner is required', trigger: 'change' }">
                  <el-select size="large" class="w-100" :placeholder="generalConstants.SELECTTRADINGPARTNER" v-model="formData.step1.trading_partner_id">
                    <el-option :label="tradingData.store_name" :value="tradingData.trading_partner_id" :key="tradingData" v-for="tradingData in tradingPartnerData"></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="mb-5">
                  <label class="required fs-6 fw-bold mb-2">Customer Name</label>
                  <el-form-item prop="step1.customer_name" :rules="{ type: 'string', required: true, message: 'Customer name is required', trigger: 'change' }">
                  <el-input size="large" v-model="formData.step1.customer_name" placeholder="Customer Name"/>
                  </el-form-item>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-5">
                  <label class="required fs-6 fw-bold mb-2">Customer Email</label>
                  <el-form-item prop="step1.customer_email" :rules="{ type: 'email', required: true, message: 'Customer email is required', trigger: 'change' }">
                  <el-input size="large" v-model="formData.step1.customer_email" placeholder="Customer Email"/>
                  </el-form-item>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-5">
                  <label class="fs-6 fw-bold mb-2">Customer Company</label>
                  <el-form-item prop="step1.customer_company">
                  <el-input size="large" v-model="formData.step1.customer_company" placeholder="Customer Company"/>
                  </el-form-item>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="mb-5">
                  <label class="fs-6 fw-bold mb-2">Customer Phone</label>
                  <el-form-item prop="step1.customer_phone">
                  <el-input size="large" v-model="formData.step1.customer_phone" placeholder="Customer Phone"/>
                  </el-form-item>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-5">
                  <label class="fs-6 fw-bold mb-2">Corporate Name</label>
                  <el-form-item prop="step1.customer_corporate_name">
                  <el-input size="large" v-model="formData.step1.customer_corporate_name" placeholder="Corporate Name"/>
                  </el-form-item>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-5">
                  <label class="fs-6 fw-bold mb-2">Corporate Department Name</label>
                  <el-form-item prop="step1.customer_corporate_department_name">
                  <el-input size="large" v-model="formData.step1.customer_corporate_department_name" placeholder="Corporate Department Name"/>
                  </el-form-item>
                </div>
              </div>
            </div>
          </div>
          <div v-if="active===1"> 
            <div class="row">
              <div class="col-md-auto fv-row">
                <div class="mb-5 mt-5">
                  <label class="required fs-6 fw-bold mb-2">Product Type</label>
                  <el-form-item prop="resource">
                    <el-radio-group v-model="formData.step2.product_type">
                      <el-radio label="Existing" />
                      <el-radio label="Custom" />
                    </el-radio-group>
                  </el-form-item>
                </div>
              </div>
              <div class="col-md mt-5">
                <button class="mb-2 btn btn-primary float-end" @click.prevent="onAddItem"><i class="bi bi-plus-circle-fill fs-4"></i> Add Product</button>
              </div>
            </div>
            
            <div v-if="formData.step2.product_type === 'Existing'"> 
              <div v-for="(pdata, index) in formData.step2['existProductData']" :key="index" v-bind:id="'row_'+index">
                <div class="custom-inner-card position-relative mb-3 mb-md-5">
                  <a href="javascript:void(0);" v-if="index > 0" class="menu-link px-3 position-absolute" @click.prevent="remove(index)"> <i class="bi bi-trash text-danger fa-lg"></i></a>
                <div class="row">
                  <div class="col-auto">
                    <label class="required fs-6 fw-bold mb-2">Product</label>
                    <el-form-item :prop="'step2.existProductData.'+index+'.order_product_id'" :rules="{ required: true, message: 'Product is required', trigger: 'change' }">
                      <el-select size="large" v-model="pdata.order_product_id" placeholder="Select Product" name="product" @change="skuSearchAsync($event)" filterable>
                        <el-option value="">Select Product</el-option>
                        <el-option v-for="(product, index) in productData" :key="index" :label="product.name" :value="product.product_id" />
                      </el-select>
                    </el-form-item>
                  </div>
                  <div class="col-md-auto">
                    <label class="required fs-6 fw-bold mb-2">Product SKU</label>
                    <el-form-item :prop="'step2.existProductData.'+index+'.product_sku'" :rules="{ required: true, message: 'Product SKU is required', trigger: 'change' }">
                    <el-select size="large" @change="getWeightBySKU(pdata.product_sku,index)" v-model="pdata.product_sku" placeholder="Select Product" name="product" filterable>
                      <el-option value="">Select Product SKU</el-option>
                      <el-option v-for="(sku, index) in skuData" :key="index" :label="sku.sku" :value="sku.sku" />
                    </el-select>
                    </el-form-item>
                  </div>
                </div>
              
                   <div class="row">
                  <div class="col-md-2">
                      <label class="required fs-6 fw-bold mb-2">Product Price</label>
                      <el-form-item :prop="'step2.existProductData.'+index+'.product_price'" :rules="{ required: true, message: 'Product price is required', trigger: 'change' }">
                        <el-input size="large" v-model="pdata.product_price" placeholder="Product Price" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"/>
                      </el-form-item>
                  </div>
                  <div class="col-md-2">
                    <label class="required fs-6 fw-bold mb-2">Product Quantity</label>
                    <el-form-item :prop="'step2.existProductData.'+index+'.product_quantity'" :rules="{ required: true, message: 'Product quantity is required', trigger: 'change' }">
                      <el-input size="large" v-model="pdata.product_quantity" placeholder="Product Quantity" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" />
                    </el-form-item>
                  </div>
                  <div class="col-md-2">
                    <label class="required fs-6 fw-bold mb-2">Product Weight Unit</label>
                    <el-form-item :prop="'step2.existProductData.'+index+'.product_weight_unit'" :rules="{ required: true, message: 'Product weight unit is required', trigger: 'change' }">
                      <el-select v-model="pdata.product_weight_unit" :placeholder="packageConstants.CHOOSE_WEIGHT" size="large" class="w-100">
                        <el-option :label="weightval" :value="weightkey" :key="weightkey"  v-for="(weightval, weightkey) in weightClass.value"></el-option>
                      </el-select>
                  </el-form-item>
                  </div>
                  <div class="col-md-2">
                    <label class="required fs-6 fw-bold mb-2">Product Weight</label>
                    <el-form-item :prop="'step2.existProductData.'+index+'.product_weight'" :rules="{ required: true, message: 'Product weight is required', trigger: 'change' }">
                      <el-input size="large" v-model="pdata.product_weight" placeholder="Product Weight" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" />
                    </el-form-item>
                  </div>
                  <div class="col-md-2">
                    <label class="fs-6 fw-bold mb-2">Product Production Days</label>
                    <el-form-item :prop="'step2.existProductData.'+index+'.product_production_days'">
                      <el-input size="large" v-model="pdata.product_production_days" placeholder="Product Production Days" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" />
                    </el-form-item>
                  </div>
                  <div class="col-md-2">
                    <label class="fs-6 fw-bold mb-2">Product Shipping Days</label>
                    <el-form-item :prop="'step2.existProductData.'+index+'.product_shipping_days'">
                      <el-input size="large" v-model="pdata.product_shipping_days" placeholder="Product Shipping Days" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" />
                    </el-form-item>
                  </div>
                </div>
                <div class="row ">
                  <div class="col-md-4 fv-row">
                    <div class="mb-5">
                      <label class="fs-6 fw-bold mb-2">Additional Information</label>
                      <el-form-item :prop="'step2.existProductData.'+index+'.additional_information'">
                        <el-input size="large" :autosize="{ minRows: 2, maxRows: 4 }" v-model="pdata.additional_information" type="textarea" placeholder="Additional Information" />
                      </el-form-item>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>

            <div v-if="formData.step2.product_type==='Custom'"> 
              <div v-for="(pdata, index) in formData.step2['newProductData']" :key="index" v-bind:id="'row_'+index">
                <div class="custom-inner-card position-relative mb-3 mb-md-5">
                  <a href="javascript:void(0);" v-if="index > 0" class="menu-link px-3 position-absolute" @click.prevent="remove(index)"> <i class="bi bi-trash text-danger fa-lg"></i></a>
                  <div class="row">
                  <div class="col-md">
                    <label class="required fs-6 fw-bold mb-2">Product Name</label>
                    <el-form-item :prop="'step2.newProductData.'+index+'.product_name'" :rules="{ required: true, message: 'Product name is required', trigger: 'change' }">
                      <el-input size="large" v-model="pdata.product_name" placeholder="Product Name"/>
                    </el-form-item>
                  </div>
                  <div class="col-md">
                    <label class="required fs-6 fw-bold mb-2">Product SKU</label>
                    <el-form-item :prop="'step2.newProductData.'+index+'.product_sku'" :rules="{ required: true, message: 'Product sku is required', trigger: 'change' }">
                      <el-input size="large" class="text-capitalize" v-model="pdata.product_sku" placeholder="Product SKU"/>
                    </el-form-item>
                  </div>
                  <div class="col-md">
                    <label class="required fs-6 fw-bold mb-2">Product Size</label>
                    <el-form-item :prop="'step2.newProductData.'+index+'.product_size'" :rules="{ required: true, message: 'Product size is required', trigger: 'change' }">
                      <el-input size="large" v-model="pdata.product_size" placeholder="Product Size"/>
                    </el-form-item>
                  </div>
                  <div class="col-md">
                    <label class="required fs-6 fw-bold mb-2">Product Height</label>
                    <el-form-item :prop="'step2.newProductData.'+index+'.product_height'" :rules="{ required: true, message: 'Product height is required', trigger: 'change' }">
                      <el-input size="large" v-model="pdata.product_height" placeholder="Product Height" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"/>
                    </el-form-item>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md">
                    <label class="required fs-6 fw-bold mb-2">Product Width</label>
                    <el-form-item :prop="'step2.newProductData.'+index+'.product_width'" :rules="{ required: true, message: 'Product width is required', trigger: 'change' }">
                      <el-input size="large" v-model="pdata.product_width" placeholder="Product Width" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"/>
                    </el-form-item>
                  </div>
                  <div class="col-md">
                    <label class="required fs-6 fw-bold mb-2">Product Price</label>
                    <el-form-item :prop="'step2.newProductData.'+index+'.product_price'" :rules="{ required: true, message: 'Product price is required', trigger: 'change' }">
                      <el-input size="large" type="number" v-model="pdata.product_price" placeholder="Product Price"/>
                    </el-form-item>
                  </div>
                  <div class="col-md">
                    <label class="required fs-6 fw-bold mb-2">Product Quantity</label>
                    <el-form-item :prop="'step2.newProductData.'+index+'.product_quantity'" :rules="{ required: true, message: 'Product quantity is required', trigger: 'change' }">
                      <el-input size="large" type="number" v-model="pdata.product_quantity" placeholder="Product Quantity"/>
                    </el-form-item>
                  </div>
                  <div class="col-md">
                    <label class="required fs-6 fw-bold mb-2">Product Weight Unit</label>
                    <el-form-item :prop="'step2.newProductData.'+index+'.product_weight_unit'" :rules="{ required: true, message: 'Product weight unit is required', trigger: 'change' }">
                      <el-select v-model="pdata.product_weight_unit" :placeholder="packageConstants.CHOOSE_WEIGHT" size="large" class="w-100">
                        <el-option :label="weightval" :value="weightkey" :key="weightkey"  v-for="(weightval, weightkey) in weightClass.value"></el-option>
                      </el-select>
                  </el-form-item>
                  </div>
                  <div class="col-md">
                    <label class="required fs-6 fw-bold mb-2">Product Weight</label>
                    <el-form-item :prop="'step2.newProductData.'+index+'.product_weight'" :rules="{ required: true, message: 'Product weight is required', trigger: 'change' }">
                      <el-input size="large" v-model="pdata.product_weight" placeholder="Product Weight" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" />
                    </el-form-item>
                  </div>
                  <div class="col-md">
                    <label class="fs-6 fw-bold mb-2">Product Production Days</label>
                    <el-form-item :prop="'step2.newProductData.'+index+'.product_production_days'">
                      <el-input size="large" v-model="pdata.product_production_days" placeholder="Product Production Days" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" />
                    </el-form-item>
                  </div>
                  <div class="col-md">
                    <label class="fs-6 fw-bold mb-2">Product Shipping Days</label>
                    <el-form-item :prop="'step2.newProductData.'+index+'.product_shipping_days'">
                      <el-input size="large" v-model="pdata.product_shipping_days" placeholder="Product Shipping Days" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');" />
                    </el-form-item>
                  </div>
                </div>

  
                <div class="row  mt-3">
                 <div class="col-md-6">
                   <label class="required fs-6 fw-bold mt-0 mb-3">Product Options & Attributes</label>
                  <el-table :data="tableData" v-loading="loading" style="width: 100%">
                    <el-table-column label="Product Options" class="required" width="250">
                      <el-form-item :prop="'pdata.product_options.'+index+'.option_title'" v-for="(option, index) in pdata.product_options" :key="'options'+index" >
                        <el-input size="large" v-model="option.option_title" placeholder="Product Option"/>
                      </el-form-item>
                    </el-table-column>
                    <el-table-column label="Product Attributes" class="required" width="250">
                      <el-form-item :prop="'pdata.product_options.'+index+'.option_value'" v-for="(option, index) in pdata.product_options" :key="'attributes'+index">
                        <el-input size="large" v-model="option.option_value" placeholder="Product Attribute"/>
                      </el-form-item>
                    </el-table-column>
                    <el-table-column label="Action" width="100" align="center">
                      <el-form-item v-for="(option, optionindex) in pdata.product_options" :key="'actions'+index">
                        <el-button v-if="optionindex!=0" type="text" size="small" @click="deleteOption(index,optionindex)" ><i class="bi bi-trash text-danger"></i></el-button>
                        <el-button v-else type="text" size="small"><i class="bi bi-dash"></i></el-button>
                      </el-form-item>
                  </el-table-column>
                  </el-table>
                 </div>
                  <div class="col-md-6">
                    <div class="mb-5">
                      <label class="fs-6 fw-bold mb-2">Additional Information</label>
                      <el-form-item :prop="'step2.newProductData.'+index+'.additional_information'">
                        <el-input size="large" :autosize="{ minRows: 5, maxRows: 4 }" v-model="pdata.additional_information" type="textarea" placeholder="Additional Information" />
                      </el-form-item>
                    </div>
                  </div>
                </div>
                <a class="btn btn-primary mt-4 mb-3" type="button" @click="addOption(index)"><i class="bi bi-plus-circle-fill fs-4"></i>Add More Options</a>
                </div>
              </div>
            </div>
          </div>
          <div v-if="active===2">
            <div class="row">
              <div class="col-md-4">
              <h5 class="mt-5">Shipping Address</h5>
                <label class="required fs-6 fw-bold mb-2">Name</label>
                <el-form-item prop="step3.shipping_address.name" :rules="{ required: true, message: 'Name is required', trigger: 'change' }">
                  <el-input size="large" v-model="formData.step3.shipping_address.name" placeholder="Name"/>
                </el-form-item>

                <label class="fs-6 fw-bold mb-2">Company</label>
                <el-form-item prop="step3.shipping_address.company">
                  <el-input size="large" v-model="formData.step3.shipping_address.company" placeholder="Company"/>
                </el-form-item>

                <label class="required fs-6 fw-bold mb-2">Address 1</label>
                <el-form-item prop="step3.shipping_address.address1" :rules="{ required: true, message: 'Address 1 is required', trigger: 'change' }">
                  <el-input size="large" v-model="formData.step3.shipping_address.address1" placeholder="Address 1"/>
                </el-form-item>

                <label class="fs-6 fw-bold mb-2">Address 2</label>
                <el-form-item prop="step3.shipping_address.address2">
                  <el-input size="large" v-model="formData.step3.shipping_address.address2" placeholder="Address 2"/>
                </el-form-item>

                <label class="required fs-6 fw-bold mb-2">City</label>
                <el-form-item prop="step3.shipping_address.city" :rules="{ required: true, message: 'City is required', trigger: 'change' }">
                  <el-input size="large" v-model="formData.step3.shipping_address.city" placeholder="City"/>
                </el-form-item>

                <label class="required fs-6 fw-bold mb-2">Postcode</label>
                <el-form-item prop="step3.shipping_address.postcode" :rules="{ required: true, message: 'Postcode is required', trigger: 'change' }">
                  <el-input size="large" v-model="formData.step3.shipping_address.postcode" placeholder="Postcode"/>
                </el-form-item>

                <label class="required fs-6 fw-bold mb-2">State</label>
                <el-form-item prop="step3.shipping_address.state" :rules="{ required: true, message: 'State is required', trigger: 'change' }">
                  <el-input size="large" v-model="formData.step3.shipping_address.state" placeholder="State"/>
                </el-form-item>

                <label class="required fs-6 fw-bold mb-2">State Code</label>
                <el-form-item prop="step3.shipping_address.state_code" :rules="{ required: true, message: 'State code is required', trigger: 'change' }">
                  <el-input size="large" v-model="formData.step3.shipping_address.state_code" placeholder="State Code"/>
                </el-form-item>

                <label class="required fs-6 fw-bold mb-2">Country</label>
                <el-form-item prop="step3.shipping_address.country" :rules="{ required: true, message: 'Country is required', trigger: 'change' }">
                  <el-input size="large" v-model="formData.step3.shipping_address.country" placeholder="Country"/>
                </el-form-item>

                <label class="required fs-6 fw-bold mb-2">Country Code</label>
                <el-form-item prop="step3.shipping_address.country_code" :rules="{ required: true, message: 'Country code is required', trigger: 'change' }">
                  <el-input size="large" v-model="formData.step3.shipping_address.country_code" placeholder="Country Code"/>
                </el-form-item>

                <label class="required fs-6 fw-bold mb-2">Phone</label>
                <el-form-item prop="step3.shipping_address.phone" :rules="{ required: true, message: 'Phone is required', trigger: 'change' }">
                  <el-input size="large" v-model="formData.step3.shipping_address.phone" placeholder="Phone"/>
                </el-form-item>
              </div>

              <div class="col-md-4">
                <div class="d-flex align-items-baseline justify-content-between">
                  <h5 class="mt-5 pe-3 mb-0">Billing Address</h5> 
                  <el-checkbox v-model="shippingCheckbox" @change="sameShippingAddress($event)" label="Same as shipping address" size="large" />
                </div>
                <label class="required fs-6 fw-bold mb-2">Name</label>
                <el-form-item prop="step3.billing_address.name" :rules="{ required: true, message: 'Name is required', trigger: 'change' }">
                  <el-input size="large" v-model="formData.step3.billing_address.name" placeholder="Name" />
                </el-form-item>

                <label class="fs-6 fw-bold mb-2">Company</label>
                <el-form-item prop="step3.billing_address.company">
                  <el-input size="large" v-model="formData.step3.billing_address.company" placeholder="Company" />
                </el-form-item>

                <label class="required fs-6 fw-bold mb-2">Address 1</label>
                <el-form-item prop="step3.billing_address.address1" :rules="{ required: true, message: 'Address 1 is required', trigger: 'change' }">
                  <el-input size="large" v-model="formData.step3.billing_address.address1" placeholder="Address 1" />
                </el-form-item>

                <label class="fs-6 fw-bold mb-2">Address 2</label>
                <el-form-item prop="step3.billing_address.address2">
                  <el-input size="large" v-model="formData.step3.billing_address.address2" placeholder="Address 2" />
                </el-form-item>

                <label class="required fs-6 fw-bold mb-2">City</label>
                <el-form-item prop="step3.billing_address.city" :rules="{ required: true, message: 'City is required', trigger: 'change' }">
                  <el-input size="large" v-model="formData.step3.billing_address.city" placeholder="City" />
                </el-form-item>

                <label class="required fs-6 fw-bold mb-2">Postcode</label>
                <el-form-item prop="step3.billing_address.postcode" :rules="{ required: true, message: 'Postcode is required', trigger: 'change' }">
                  <el-input size="large" v-model="formData.step3.billing_address.postcode" placeholder="Postcode" />
                </el-form-item>

                <label class="required fs-6 fw-bold mb-2">State</label>
                <el-form-item prop="step3.billing_address.state" :rules="{ required: true, message: 'State is required', trigger: 'change' }">
                  <el-input size="large" v-model="formData.step3.billing_address.state" placeholder="State" />
                </el-form-item>

                <label class="fs-6 fw-bold mb-2">State Code</label>
                <el-form-item prop="step3.billing_address.state_code">
                  <el-input size="large" v-model="formData.step3.billing_address.state_code" placeholder="State Code"/>
                </el-form-item>

                <label class="required fs-6 fw-bold mb-2">Country</label>
                <el-form-item prop="step3.billing_address.country" :rules="{ required: true, message: 'Country is required', trigger: 'change' }">
                  <el-input size="large" v-model="formData.step3.billing_address.country" placeholder="Country" />
                </el-form-item>

                <label class="fs-6 fw-bold mb-2">Country Code</label>
                <el-form-item prop="step3.billing_address.country_code">
                  <el-input size="large" v-model="formData.step3.billing_address.country_code" placeholder="Country Code"/>
                </el-form-item>

                <label class="required fs-6 fw-bold mb-2">Phone</label>
                <el-form-item prop="step3.billing_address.phone" :rules="{ required: true, message: 'Phone is required', trigger: 'change' }">
                  <el-input size="large" v-model="formData.step3.billing_address.phone" placeholder="Phone" />
                </el-form-item>
              </div>

              <div class="col-md-4">
                <div class="d-flex align-items-baseline">
                  <h5 class="mt-5 me-2">Blind Address</h5>
                  <el-switch v-model="blindCheckbox" />
                </div>
                <template v-if="blindCheckbox == true">
                  <label class="required fs-6 fw-bold mb-2">Name</label>
                  <el-form-item prop="step3.blind_address.name" :rules="{ required: true, message: 'Name is required', trigger: 'change' }">
                    <el-input size="large" v-model="formData.step3.blind_address.name" placeholder="Name"/>
                  </el-form-item>

                  <label class="fs-6 fw-bold mb-2">Company</label>
                  <el-form-item prop="step3.blind_address.company">
                    <el-input size="large" v-model="formData.step3.blind_address.company" placeholder="Company"/>
                  </el-form-item>

                  <label class="required fs-6 fw-bold mb-2">Address 1</label>
                  <el-form-item prop="step3.blind_address.address1" :rules="{ required: true, message: 'Address 1 is required', trigger: 'change' }">
                    <el-input size="large" v-model="formData.step3.blind_address.address1" placeholder="Address 1"/>
                  </el-form-item>

                  <label class="fs-6 fw-bold mb-2">Address 2</label>
                  <el-form-item prop="step3.blind_address.address2">
                    <el-input size="large" v-model="formData.step3.blind_address.address2" placeholder="Address 2"/>
                  </el-form-item>

                  <label class="required fs-6 fw-bold mb-2">City</label>
                  <el-form-item prop="step3.blind_address.city" :rules="{ required: true, message: 'City is required', trigger: 'change' }">
                    <el-input size="large" v-model="formData.step3.blind_address.city" placeholder="City"/>
                  </el-form-item>

                  <label class="required fs-6 fw-bold mb-2">Postcode</label>
                  <el-form-item prop="step3.blind_address.postcode" :rules="{ required: true, message: 'Postcode is required', trigger: 'change' }">
                    <el-input size="large" v-model="formData.step3.blind_address.postcode" placeholder="Postcode"/>
                  </el-form-item>

                  <label class="required fs-6 fw-bold mb-2">State</label>
                  <el-form-item prop="step3.blind_address.state" :rules="{ required: true, message: 'State is required', trigger: 'change' }">
                    <el-input size="large" v-model="formData.step3.blind_address.state" placeholder="State"/>
                  </el-form-item>

                  <label class="required fs-6 fw-bold mb-2">State Code</label>
                  <el-form-item prop="step3.blind_address.state_code" :rules="{ required: true, message: 'State code is required', trigger: 'change' }">
                    <el-input size="large" v-model="formData.step3.blind_address.state_code" placeholder="State Code"/>
                  </el-form-item>

                  <label class="required fs-6 fw-bold mb-2">Country</label>
                  <el-form-item prop="step3.blind_address.country" :rules="{ required: true, message: 'Country is required', trigger: 'change' }">
                    <el-input size="large" v-model="formData.step3.blind_address.country" placeholder="Country"/>
                  </el-form-item>

                  <label class="required fs-6 fw-bold mb-2">Country Code</label>
                  <el-form-item prop="step3.blind_address.country_code" :rules="{ required: true, message: 'Country code is required', trigger: 'change' }">
                    <el-input size="large" v-model="formData.step3.blind_address.country_code" placeholder="Country Code"/>
                  </el-form-item>

                  <label class="required fs-6 fw-bold mb-2">Phone</label>
                  <el-form-item prop="step3.blind_address.phone" :rules="{ required: true, message: 'Phone is required', trigger: 'change' }">
                    <el-input size="large" v-model="formData.step3.blind_address.phone" placeholder="Phone"/>
                  </el-form-item>
                </template>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-md-12">
                <h5>Shipping Methods</h5>
                <div class="row mt-1">
                  <div class="col-md-4">
                    <label class="required fs-6 fw-bold mb-2 form-label">{{ordersConstants.SHIPPING_METHOD}}</label>
                    <el-form-item prop="step3.shipping_method" :rules="{ required: true, message: ordersConstants.SHIIPING_METHOD_REQUIRED, trigger: 'blur',}">
                    <el-select v-model="formData.step3.shipping_method" placeholder="Select Shipping Method" class="w-100" size="large" @change="getShippingType(formData.step3.shipping_method,$event)">
                      <el-option :label="methodVal.shipping_method" :value="methodVal.shipping_method_id" :key="methodVal.shipping_method_id"  v-for="(methodVal, methodKey) in shippingMethod"></el-option>
                    </el-select>
                    </el-form-item>
                  </div>
                  <div class="col-md-4">
                    <label class="required fs-6 fw-bold mb-2">{{ordersConstants.SHIPPING_TYPE}}</label>
                    <el-form-item prop="step3.shipping_type" :rules="{required: true, message: 'Shipping type is required', trigger: 'blur',}">
                      <el-select v-model="formData.step3.shipping_type" placeholder="Select Shipping Type" class="w-100" size="large">
                        <el-option :label="typeVal.shipping_type" :value="typeVal.shipping_type" :key="typeVal.shipping_type_id"  v-for="(typeVal, typeKey) in shippingType"></el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                </div>
              </div>
            </div> 
          </div>
          <div v-if="active===3">
            <div class="row mt-5">
              <div class="col-md-4">
                <label class="fs-6 fw-bold mb-2">Order Weight</label>
                <el-form-item prop="step4.order_weight">
                  <el-input size="large" type="number" readonly v-model="formData.step4.order_weight" placeholder="Order Weight" />
                </el-form-item>
              </div>
              <div class="col-md-4">
                <label class="fs-6 fw-bold mb-2">Order Shipping Days</label>
                <el-form-item prop="step4.order_shipping_days">
                  <el-input size="large" type="number" v-model="formData.step4.order_shipping_days" placeholder="Order Shipping Days" />
                </el-form-item>
              </div>
              <div class="col-md-4">
                <label class="fs-6 fw-bold mb-2">Order Production Days</label>
                <el-form-item prop="step4.order_production_days">
                  <el-input size="large" type="number" v-model="formData.step4.order_production_days" placeholder="Order Production Days" />
                </el-form-item>
              </div>
              <div class="col-md-4">
                <label class="fs-6 fw-bold mb-2">Order Date</label>
                <el-form-item prop="step4.order_date">
                  <el-date-picker :disabled-date="disabledDates" size="large" class="w-100" readonly v-model="formData.step4.order_date" type="datetime" format="YYYY/MM/DD HH:mm:ss" placeholder="Select Order Date" />
                </el-form-item>
              </div>
              <div class="col-md-4">
                <label class="fs-6 fw-bold mb-2">Order Due Date</label>
                <el-form-item prop="step4.order_due_date">
                  <el-date-picker size="large" class="w-100" v-model="formData.step4.order_due_date" type="date" format="YYYY/MM/DD" placeholder="Select Order Due Date" />
                </el-form-item>
              </div>
              <div class="col-md-4">
                <label class="fs-6 fw-bold mb-2">Order Production Due Date</label>
                <el-form-item prop="step4.order_production_due_date">
                  <el-date-picker size="large" class="w-100" v-model="formData.step4.order_production_due_date" type="date" format="YYYY/MM/DD" placeholder="Select Order Production Due Date" />
                </el-form-item>
              </div>
            </div>
          </div>
          <div v-if="active===4">
            <div class="row mt-5">
              <!-- <div class="col-md-3">
                <div class="mb-5">
                  <label class="required fs-6 fw-bold mb-2">Shipping Price</label>
                  <el-form-item prop="step5.shipping_price" :rules="{ required: true, message: 'Shipping Price is required', trigger: 'change' }">
                    <el-input size="large" type="number" v-model="formData.step5.shipping_price" placeholder="Shipping Price" />
                  </el-form-item>
                </div>
              </div> -->
              <div class="col-md-4">
                  <div class="col-12">
                    <label class="fs-6 fw-bold mb-2">Discount</label>
                    <el-form-item prop="discount">
                      <el-input size="large" v-on:keyup="calculateTotalAmount" v-on:change="calculateTotalAmount" type="number" v-model="formData.step5.discount" placeholder="Discount"/>
                    </el-form-item>
                  </div>              
                  <div class="col-12">
                    <div class="mb-5">
                      <label class="fs-6 fw-bold mb-2">Shipping Charges</label>
                      <el-form-item prop="step5.shipping_charges">
                        <el-input size="large" v-on:keyup="calculateTotalAmount" type="number" v-model="formData.step5.shipping_charges" placeholder="Shipping Charges" />
                      </el-form-item>
                    </div>
                  </div>
                  <div class="col-12">
                  <label class="fs-6 fw-bold mb-2">Tax</label>
                  <el-form-item prop="tax">
                    <el-input size="large" v-on:keyup="calculateTotalAmount" v-on:change="calculateTotalAmount" type="number" v-model="formData.step5.tax" placeholder="Tax"/>
                  </el-form-item>
                </div>
                 <div class="col-12">
                    <div class="mb-5">
                      <label class="fs-6 fw-bold mb-2">Subtotal</label>
                      <el-form-item prop="step5.subtotal" :rules="{ required: true, message: 'Subtotal Price is required', trigger: 'change' }">
                        <el-input size="large" readonly type="number" v-model="formData.step5.subtotal" placeholder="Subtotal"/>
                      </el-form-item>
                    </div>
                  </div>
                <div class="col-12">
                <div class="d-flex align-item-baseline total">
                  <label>Total Amount :</label>
                  <h3>{{totalAmount}}</h3>
                </div>
              </div> 
              </div>
              <div class="col-md-4">
                  <div class="col-12">
                <label class="fs-6 fw-bold mb-2">Payment Date</label>
                <el-form-item prop="payment_date">
                  <el-date-picker class="w-100" size="large" :disabled-date="disabledDates" v-model="formData.step5.payment_date" type="datetime" format="YYYY/MM/DD HH:mm:ss" placeholder="Select Payment Date" />
                </el-form-item>
              </div>
              <div class="col-12">
                <label class="fs-6 fw-bold mb-2">Payment Method</label>
                <el-form-item prop="payment_method">
                <el-input size="large" v-model="formData.step5.payment_method" placeholder="Payment Method"/>
                </el-form-item>

              </div>
              <div class="col-12">
                <label class="required fs-6 fw-bold mb-2">Payment Status</label>
                <el-form-item prop="step5.payment_status" :rules="{ required: true, message: 'Payment status is required', trigger: 'change' }">
                  <el-select size="large" v-model="formData.step5.payment_status" placeholder="Payment Status" name="payment_status" class="w-100">
                    <el-option label="Completed" value="Completed" />
                    <el-option label="Processing" value="Processing" />
                    <el-option label="Pending" value="Pending" />
                  </el-select>
                </el-form-item>
              </div>
              </div>
            </div>
          </div>
          
          <div class="col-md-12 mt-10 d-flex justify-content-between">
            <div>
              <button type="button" size="large" v-bind:class="btnHide" :disabled="disabled" v-if="active > 0" class=" btn btn-primary me-2" :loading="loading" @click="previous">{{generalConstants.PREVIOUS}}</button>
            </div>
            <div>
              <button type="button" size="large" v-bind:class="btnHide" class="btn btn-primary me-2" v-if="active == 4" @click="submit">Confirm Order<span v-if="loading" class="spinner-border spinner-border-sm align-middle ms-2"></span></button>
              <button type="button" size="large" v-bind:class="btnHide" class="btn btn-primary me-2" v-if="active < 4" @click="next()">{{generalConstants.NEXT}}<span v-if="loading" class="spinner-border spinner-border-sm align-middle ms-2"></span></button>
              <button type="button" size="large" class=" btn btn-secondary me-2" v-bind:class="btnHide" @click.prevent="$router.push({ name: 'orders'})">{{generalConstants.CANCEL}}</button>
            </div>
        </div>
        </el-form>
    </div>
  </div>
</template>

<script>
import { reactive, ref, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { notificationFire } from "@/composable/notification.js";
import { useRouter, onBeforeRouteLeave } from 'vue-router'
import globalConstant from "@/core/data/globalConstant.js";
import useEventBus from '../../composable/useEventBus';
import { setCurrentPageActionButtons } from "@/core/helpers/actionButtons";
import moment from "moment";

export default {
  name: "add-new-order",
  components: {}, 
  setup() {
    const { onEvent, emitEvent } = useEventBus();
    const formRef = ref(null);
    const router = useRouter();
    const loading = ref(false);
    const generalConstants = globalConstant.general;
    const ordersConstants = globalConstant.orders;
    const packageConstants = globalConstant.package;
    const shippingMethod = ref([]);
    const shippingType = ref([]);
    const tradingPartnerData = ref([]);
    const skuData = ref([]);
    const totalAmount = ref(0);
    const weightClass = reactive({});
    const tableData = reactive([
      {
        option_id: "",
        option_key: "",
        option_title: "",
        option_value: "",
        attribute_id: "",
      },
    ]);
    const formData = reactive({
      step1: {
        customer_name: "",
        customer_email: "",
        active_tab: 0,
        customer_company: "",
        customer_phone: "",
        customer_corporate_name: "",
        customer_corporate_department_name: "",
        trading_partner_id: ""
      },
      step2: {
        product_type: "Existing",
        existProductData: [{
          order_product_id: "",
          products: '',
          product_sku: "",
          product_price: "",
          product_quantity: "",
          product_weight: "",
          product_weight_unit: "",
          product_production_days: "",
          product_shipping_days: "",
          product_subtotal: 0,
          additional_information: "",
        }],
        newProductData: [{
          order_product_id: 0,
          product_name: "",
          product_name: "",
          product_sku: "",
          product_size: "",
          product_height: "",
          product_width: "",
          product_price: "",
          product_quantity: "",
          product_weight: "",
          product_weight_unit: "",
          product_production_days: "",
          product_shipping_days: "",
          product_subtotal: 0,
          additional_information: "",
          product_options: [
            {
              option_id: "",
              option_key: "",
              option_title: "",
              option_value: "",
              attribute_id: "",
            },
          ],
        }]
      },
      step3: {
        billing_address: {
          name: "",
          company: "",
          address1: "",
          address2: "",
          city: "",
          postcode: "",
          state: "",
          state_code: "",
          country: "",
          country_code: "",
          phone: "",
        },
        blind_address: {
          name: "",
          company: "",
          address1: "",
          address2: "",
          city: "",
          postcode: "",
          state: "",
          state_code: "",
          country: "",
          country_code: "",
          phone: "",
        },
        shipping_address: {
          name: "",
          company: "",
          address1: "",
          address2: "",
          city: "",
          postcode: "",
          state: "",
          state_code: "",
          country: "",
          country_code: "",
          phone: "",
        },
        shipping_method: "",
        shipping_method_name: "",
        shipping_type: "",
      },
      step4: {
        order_weight: "",
        order_shipping_days: "",
        order_production_days: "",
        order_date: "",
        order_due_date: "",
        order_production_due_date: ""
      },
      step5: {
        payment_status: "",
        shipping_price: "",
        shipping_charges: "",
        discount: "",
        tax: "",
        subtotal: "",
        total_amount: "",
        payment_method: "",
        invoice_number: "",
        payment_date: "",
        payment_processing_fees: ""
      },
    });
    const productData = ref([]);
    const localstorageData = reactive({});
    const active = ref(0);
    const rules = ref({});
    const shippingCheckbox = ref(false);
    const blindCheckbox = ref(false);
    const customers = ref([]);
    const searchParams = reactive({
      search_customer: "",
      search_sku: "",
    });
    let timeout = 200;

    //Get click event of toolbar buttons
    onEvent('actionName', (actionName) => {
      if(actionName == "back"){
        router.push('/orders');
      }
    })

    onBeforeRouteLeave((to, from) => {
      if ( typeof (localstorageData.value) !== 'undefined' && localstorageData.value != null ) {
        localStorage.removeItem("orderData");
      }
    })

    //Add row for extra field table
    const onAddItem = () => {
      if(formData.step2.product_type == 'Existing'){
        formData.step2.existProductData.push({
          order_product_id: "",
          product_options: [],
          products: '',
          product_sku: "",
          product_price: "",
          product_quantity: "",
          product_weight: "",
          product_weight_unit: "",
          product_production_days: "",
          product_shipping_days: "",
          additional_information: "",
        });
      }else{
        formData.step2.newProductData.push({
          order_product_id: 0,
          product_name: "",
          product_name: "",
          product_sku: "",
          product_size: "",
          product_height: "",
          product_width: "",
          product_price: "",
          product_quantity: "",
          product_weight: "",
          product_weight_unit: "",
          product_production_days: "",
          product_shipping_days: "",
          additional_information: "",
          product_options: [
            {
              option_id: "",
              option_key: "",
              option_title: "",
              option_value: "",
              attribute_id: "",
            },
          ],
        });
      }
    };

    const remove = async (index) => {
      if(formData.step2.product_type == 'Existing'){
        formData.step2.existProductData.splice(index, 1);
      }else{
        formData.step2.newProductData.splice(index, 1);
      }
    }

    const disabledDates = (date) => {
      if(date > new Date()){
        return true;
      }
    }

    const getWeightBySKU = (sku,index) => {
      let weightParams = {'sku':sku};
      ApiService.post("get-product-weight", weightParams)
      .then(({ data }) => {
        if (data.data) {
          formData.step2.existProductData[index].product_weight = data.data.weight;
        }
      })
      .catch((error) => {
        let message='';
        if(typeof (error.response) !== 'undefined'){
          message = error.response.data.message;
        }else if(typeof (error.message) !== 'undefined'){
          message = error.message;
        }else{
          message = "Something went wrong";
        }
        notificationFire(message, "error");
      });
    }

    //Next step button event
    const next = async () => {
      await formRef.value.validate((valid, fields) => {
        if (valid) {
          if (active.value++ > 3) active.value = 0;
          if(active.value == 3){
            formData.step4.order_date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
            let orderWeightTotal = 0;
            if(formData.step2.product_type == 'Existing'){
              if(formData.step2.existProductData.length > 0){
                formData.step2.existProductData.forEach((val) => {
                  orderWeightTotal += parseFloat(val.product_weight) * parseInt(val.product_quantity);
                });
              }
            }else{
              if(formData.step2.newProductData.length > 0){
                formData.step2.newProductData.forEach((val) => {
                  orderWeightTotal += parseFloat(val.product_weight) * parseInt(val.product_quantity);
                });
              }
            }
            formData.step4.order_weight = orderWeightTotal;
          }
          if(active.value == 4){
          formData.step5.payment_date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
            let subTotal = 0;
            if(formData.step2.product_type == 'Existing'){
              if(formData.step2.existProductData.length > 0){
                formData.step2.existProductData.forEach((val) => {
                  subTotal += parseInt(val.product_price) * parseInt(val.product_quantity);
                });
              }
            }else{
              if(formData.step2.newProductData.length > 0){
                formData.step2.newProductData.forEach((val) => {
                  subTotal += parseInt(val.product_price) * parseInt(val.product_quantity);
                });
              }
            }
            formData.step5.subtotal = subTotal;
            calculateTotalAmount();
          }
          if(active.value == 2){
            getShippingMethod();
          }
          if(active.value == 1){
            getWeightClass();
          }
          formData.step1.active_tab = active.value;
          JwtService.saveLocalStorage("orderData", formData);
        }

      });
    };

    //Previous step button event
    const previous = () => {
      if (active.value-- <= 0) active.value = 0;
      formData.step1.active_tab = active.value;
      JwtService.saveLocalStorage("orderData", formData);
    };

    // Get Weight Unit
    const getWeightClass = async() => {
      await ApiService.query("get-weight-class")
      .then(({ data }) => {
        weightClass.value = data;
      })
      .catch((error) => {
        let response = error.response;
        if (response) {
          let status = response.status;
          if (status == 404) {
            router.push({ name: "add-master-package" });
          }
        } 
      });
    }

    const calculateTotalAmount = () => {
      let subTotal = formData.step5.subtotal;
      let totalCalculateAmount = subTotal;
      if (formData.step5.discount > 0) {
        totalCalculateAmount = totalCalculateAmount - parseInt(formData.step5.discount);
      }
      if (formData.step5.tax > 0) {
        totalCalculateAmount = totalCalculateAmount + parseInt(formData.step5.tax);
      }
      if (formData.step5.shipping_charges > 0) {
        totalCalculateAmount = totalCalculateAmount + parseInt(formData.step5.shipping_charges);
      }
      totalAmount.value = totalCalculateAmount; 
    }

    const getTradingPartners = async () => {
      await ApiService.query("get-active-trading-partners")
        .then(({ data }) => {
          data.data.forEach((obj) => {
            tradingPartnerData.value.push({
              trading_partner_id: obj.trading_partner_id,
              store_name: obj.get_user_oauth[0].name,
            });
          });
        })
        .catch((error) => {
          let response = error.response;
          if (response) {
            let message = response.data.message;
            notificationFire(message, "error");
          }
        });
    };
    
    // Get shipping method list
    const getShippingMethod = async() => {
      await ApiService.query("get-shipping-method")
      .then(({ data }) => {
        shippingMethod.value = data;
      })
      .catch((error) => {
        let response = error.response;
        if (response) {
          let message = response.data.message;
          notificationFire(message, "error");
        }
      });
    }
    
    // Get shipping type list
    const getShippingType = async(shippingMethodId,index) => {
      formData.step3.shipping_method_name = shippingMethod.value[(index-1)].shipping_method;
      shippingMethod.value.map(function (value){
        if (value.shipping_method_id == shippingMethodId) {
          formData.shipping_method = value.shipping_method;
          return false;
        }
      });
      formData.shipping_type_id = '';
      shippingType.value = [];
      await ApiService.query("get-shipping-type/" + shippingMethodId)
      .then(({ data }) => {
        shippingType.value = data;
      })
      .catch((error) => {
        let response = error.response;
        if (response) {
          let message = response.data.message;
          notificationFire(message, "error");
        }
      });
    }

    //Search customers
    const customerSearchAsync = async (queryString, cb) => {
      await ApiService.query("search-customers", { params: searchParams })
      .then(({ data }) => {
        if (data.data) {
          customers.value.splice(0, customers.value.length, ...data.data);
        }
      })
      .catch((error) => {
        let message = error.message;
        notificationFire(message, "error");
      });
    };

    //Search products by SKU
    const skuSearchAsync = async (index) => {
      searchParams.productId = index;
      await ApiService.query("search-products", { params: searchParams })
      .then(({ data }) => {
        if (data.data) {
          skuData.value.splice(0, skuData.value.length, ...data.data);
        }
      
      })
      .catch((error) => {
        let message = error.message;
        notificationFire(message, "error");
      });
    };

    const sameShippingAddress = (val) => {
      if(val == true){
        formData.step3.billing_address.name = formData.step3.shipping_address.name;
        formData.step3.billing_address.company = formData.step3.shipping_address.company;
        formData.step3.billing_address.address1 = formData.step3.shipping_address.address1;
        formData.step3.billing_address.address2 = formData.step3.shipping_address.address2;
        formData.step3.billing_address.city = formData.step3.shipping_address.city;
        formData.step3.billing_address.postcode = formData.step3.shipping_address.postcode;
        formData.step3.billing_address.state = formData.step3.shipping_address.state;
        formData.step3.billing_address.state_code = formData.step3.shipping_address.state_code;
        formData.step3.billing_address.country = formData.step3.shipping_address.country;
        formData.step3.billing_address.country_code = formData.step3.shipping_address.country_code;
        formData.step3.billing_address.phone = formData.step3.shipping_address.phone;
      }
    }

    //It's used on internal customer and product search functions
    const createFilter = (queryString) => {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    };

    //Return once selected items from customer search box step 1
    const handleSelect = (item) => {
      var index = customers.value.findIndex(p => p.value == item);
      formData.step1.customer_name = customers.value[index].name;
      formData.step1.customer_email = customers.value[index].email;
    };

    //Custom product add more options and attribute function step 2
    const addOption = (index) => {
      formData.step2.newProductData[index].product_options.push({
        option_id: -1,
        option_key: "",
        option_title: "",
        option_value: "",
        attribute_id: -1,
      });
    };

    //Get product data
    const getData = async () => {
      await ApiService.query("products", { params: { sortby: "latest" } })
        .then(({ data }) => {
          productData.value = data.data;
        })
        .catch((error) => {
          error.message != 'undefined' ? notificationFire(error.message, "error") : "";
        });
    };

    //Set the size, hegiht, and width value from sizes
    const setSizeHeightWidth = (sizeObj) => {
      formData.step2.product_size = sizeObj.size;
      formData.step2.size_id = sizeObj.size_id;
      formData.step2.product_height = sizeObj.height;
      formData.step2.product_width = sizeObj.width;
    };

    //Set attribute id in product_options object after selecting product option
    const setAttribute = (event, index) => {
      formData.step2.product_options[index].attribute_id = event.master_attribute_id;
      formData.step2.product_options[index].option_value = event.attribute_name;
    };
    
    //Delete custom options from add more functionality
    const deleteOption = (index,optionindex) => {
      formData.step2.newProductData[index].product_options.splice(optionindex, 1);
    };

    //Confirm order button event
    const submit = async () => {
      
      await formRef.value.validate((valid, fields) => {

        if (valid) {
          let userInfo = JSON.parse(JwtService.getLoggedInUserInfo());
          let productType = formData.step2.product_type;
          let subTotal = formData.step5.subtotal;
          let orderData = {
            order_id: "ORDER-"+(Math.floor(Math.random()*99999) + 10000)+(typeof userInfo.id != 'undefined' ? userInfo.id : ""),
            user_id: typeof userInfo.id != 'undefined' ? userInfo.id : "",
            trading_partner_id: formData.step1.trading_partner_id,
            customer_name: formData.step1.customer_name,
            customer_email: formData.step1.customer_email,
            corporate_name: formData.step1.customer_corporate_name,
            corporate_department_name: formData.step1.customer_corporate_department_name,
            customer_phone: formData.step1.customer_phone,
            customer_company: formData.step1.customer_company,
            productType : formData.step2.product_type,
            order_items: productType == 'Existing' ? formData.step2.existProductData : formData.step2.newProductData,
            blind_shipping: blindCheckbox.value == true ? 1 : 0,
            blind_shipping_address: {
              name: formData.step3.blind_address.name,
              company: formData.step3.blind_address.company,
              address1: formData.step3.blind_address.address1,
              address2: formData.step3.blind_address.address2,
              city: formData.step3.blind_address.city,
              postcode: formData.step3.blind_address.postcode,
              state: formData.step3.blind_address.state,
              state_code: formData.step3.blind_address.state_code,
              country: formData.step3.blind_address.country,
              country_code: formData.step3.blind_address.country_code,
              phone: formData.step3.blind_address.phone,
            },
            billing_address: {
              name: shippingCheckbox.value == true ? formData.step3.shipping_address.name : formData.step3.billing_address.name,
              company: shippingCheckbox.value == true ? formData.step3.shipping_address.company : formData.step3.billing_address.company,
              address1: shippingCheckbox.value == true ? formData.step3.shipping_address.address1 : formData.step3.billing_address.address1,
              address2: shippingCheckbox.value == true ? formData.step3.shipping_address.address2 : formData.step3.billing_address.address2,
              city: shippingCheckbox.value == true ? formData.step3.shipping_address.city : formData.step3.billing_address.city,
              postcode: shippingCheckbox.value == true ? formData.step3.shipping_address.postcode : formData.step3.billing_address.postcode,
              state: shippingCheckbox.value == true ? formData.step3.shipping_address.state : formData.step3.billing_address.state,
              country: shippingCheckbox.value == true ? formData.step3.shipping_address.country : formData.step3.billing_address.country,
              phone: shippingCheckbox.value == true ? formData.step3.shipping_address.phone : formData.step3.billing_address.phone,
            },
            shipping_address: {
              name: formData.step3.shipping_address.name,
              company: formData.step3.shipping_address.company,
              address1: formData.step3.shipping_address.address1,
              address2: formData.step3.shipping_address.address2,
              city: formData.step3.shipping_address.city,
              postcode: formData.step3.shipping_address.postcode,
              state: formData.step3.shipping_address.state,
              state_code: formData.step3.shipping_address.state_code,
              country: formData.step3.shipping_address.country,
              country_code: formData.step3.shipping_address.country_code,
              phone: formData.step3.shipping_address.phone,
            },
            shipping_method: formData.step3.shipping_method,
            shipping_method_name: formData.step3.shipping_method_name,
            shipping_type: formData.step3.shipping_type,
            order_shipping_days: formData.step4.order_shipping_days ? formData.step4.order_shipping_days : 0,
            order_production_days: formData.step4.order_production_days ? formData.step4.order_production_days : 0,
            order_weight: formData.step4.order_weight ? formData.step4.order_weight : 0,
            production_due_date: formData.step4.order_production_due_date ? moment(formData.step4.order_production_due_date).format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD"),
            order_date: formData.step4.order_date ? moment(formData.step4.order_date).format("YYYY-MM-DD HH:mm:ss") : moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            order_due_date: formData.step4.order_due_date ? moment(formData.step4.order_due_date).format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD"),
            order_status: formData.step5.payment_status,
            order_creation_type: 'Manual',
            payment_details: {
              subtotal: subTotal,
              shipping_charges: formData.step5.shipping_charges,
              discount: formData.step5.discount,
              tax: formData.step5.tax,
              total_amount: totalAmount.value,
              payment_date: formData.step5.payment_date ? moment(formData.step5.payment_date).format("YYYY-MM-DD HH:mm:ss") : moment(new Date()).format("YYYY-MM-DD H:mm:ss"),
              payment_method: formData.step5.payment_method ? formData.step5.payment_method : "Offline",
              payment_status: formData.step5.payment_status,
            },
            order_extrafields: {
              additional_information: productType == "Custom" ? formData.step2.additional_information : formData.step2.additional_information,
            },
          };          

          let requestUrl = "manual-order/create";
          ApiService.post(requestUrl, orderData)
          .then((response) => {
            if (response.data) {
              let status = response.status;
              let data = response.data.data;
              let message = response.data.message;
              if (status == 200 || status == 201) {
                localStorage.removeItem("orderData");
                notificationFire(message, "success");
                if(data.is_pending == true){
                  router.push('/pending-orders/'+data.id)
                }else{
                  router.push({ name: 'order-details', params: { id: data.id  } })
                }
              }
            }
          })
          .catch((error) => {
            let message='';
            if(typeof (error.response) !== 'undefined'){
              message = error.response.data.message;
            }else if(typeof (error.message) !== 'undefined'){
              message = error.message;
            }else{
              message = "Something went wrong";
            }
            notificationFire(message, "error");
          });
        }
      });
    };

    //Generate Current Page Toolbar Dynamic Buttons
    function generateHeaderToolbarButtons(){
      return setCurrentPageActionButtons([{"name": generalConstants.BACK , 'action': 'back','class': 'btn-primary','isLoader':false, 'iconClass':"bi bi-arrow-left fs-4" }]);
    }

    //Set breadcrumbs and other needful data
    onMounted(() => {
      
      setCurrentPageBreadcrumbs("Add Order", ["Orders"]);
      getData();
      getTradingPartners();
      generateHeaderToolbarButtons();
      localstorageData.value = JSON.parse(JwtService.getLocalStorage("orderData"));

      if ( typeof (localstorageData.value) !== 'undefined' && localstorageData.value != null ) {
        formData.step1 = localstorageData.value.step1;
        formData.step2 = localstorageData.value.step2;
        formData.step3 = localstorageData.value.step3;
        formData.step5 = localstorageData.value.step5;
      }
      active.value = (localstorageData.value != null && typeof (localstorageData.value.step1.active_tab) !== 'undefined') ? localstorageData.value.step1.active_tab : 0;
      if(active.value == 0){
        customerSearchAsync();
      }
      if(active.value == 2){
        getShippingMethod();
      }
      if(active.value == 4){
        formData.step5.payment_date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        calculateTotalAmount();
      }
      if(active.value == 1){
        getWeightClass();
      }
      if(active.value == 3){
        formData.step4.order_date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        let orderWeightTotal = 0;
        if(formData.step2.product_type == 'Existing'){
          if(formData.step2.existProductData.length > 0){
            formData.step2.existProductData.forEach((val) => {
              orderWeightTotal += parseFloat(val.product_weight) * parseInt(val.product_quantity);
            });
          }
        }else{
          if(formData.step2.newProductData.length > 0){
            formData.step2.newProductData.forEach((val) => {
              orderWeightTotal += parseFloat(val.product_weight) * parseInt(val.product_quantity);
            });
          }
        }
        formData.step4.order_weight = orderWeightTotal;
      }
    });

    return {
      formData,
      tableData,
      rules,
      searchParams,
      submit,
      formRef,
      loading,
      customerSearchAsync,
      skuSearchAsync,
      handleSelect,
      active,
      next,
      previous,
      getData,
      productData,
      setSizeHeightWidth,
      localstorageData,
      addOption,
      deleteOption,
      setAttribute,
      generalConstants,
      ordersConstants,
      shippingMethod,
      getShippingType,
      shippingType,
      shippingCheckbox,
      tradingPartnerData,
      blindCheckbox,
      sameShippingAddress,
      skuData,
      totalAmount,
      calculateTotalAmount,
      weightClass,
      packageConstants,
      customers,
      onAddItem,
      remove,
      getWeightBySKU,
      disabledDates
    };
  },
};
</script>
<style>
  .steps .el-step__icon{
    width: 30px;
    height: 30px;
    font-size: 16px;
  }
  .steps .el-step__head.is-process{
    color: #009ef7;
    font-weight:700;
  }
  .steps .el-step.is-horizontal .el-step__line{
    top:50%;
    display: block;
  }
  .steps .el-step__head.is-process {  
  border-color: #009ef7;
}
.steps .el-step__main{
  color: #009ef7;
}
.steps .el-step__title.is-process{
  color: #009ef7 !important;
}
.steps  .el-step__title.is-success {
  color:#009ef7;
  font-weight: 700;
}
.steps .el-step__head.is-success {
  color:#fff;
}
.steps  .el-step__head.is-success .el-step__line{
  background-color: #009ef7;
}
.steps  .el-step__head.is-success .el-step__icon{
  border-color: #009ef7;
  background-color: #009ef7;
}
.steps  .el-step__head.is-success{
  border-color: #009ef7;
}
.steps .form-check-input {
  width: 1.30em;
  height: 1.30rem;
  margin-right: 10px;
}
.custom-inner-card{
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  padding: 15px 30px;
}
.total label{  
  font-size: 20px;  
}
.total h3{
  font-size: 24px;
  color: #0095E8;
  margin-left: 10px;
}
.menu-link {
  right: 0;
  top: 10px;
}
.text-capitalize .el-input__inner {
  text-transform: uppercase;
}
</style>